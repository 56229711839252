import React from 'react';

import styles from './category.module.css';

export default props => (
  <div>
    <h2>{props.title}</h2>
    <div className={styles.content}>
      {props.children}
    </div>
    <a className={styles.button} href={`/${props.title.toLowerCase()}`}>
      view {props.title}
    </a>
  </div>
)