import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Category from '../components/category';

import Layout from '../components/layout';


export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Levi Lewis</title>
    </Helmet>
    <Category title="Experience">
      <p>Web development since 2002.</p>
    </Category>
    <Category title="Projects">
      <p>Working on NodeWrite headless content management system.</p>
    </Category>
    <Category title="Education">
      <p>Studied computer information systems.</p>
    </Category>
    <Category title="Skills">
      <p>Familiar with frontend, backend, devops and linux administration.</p>
    </Category>
    <Category title="Movies">
      <p>
        {data.movies.edges[0].node.frontmatter.title}
      </p>
    </Category>
    <Category title="Subaru">
      <p>2006 WRX TR built by Boxer Motorsports and Andrew Tech.</p>
    </Category>
    <Category title="Games">
      <p>
        {data.games.edges[0].node.frontmatter.title}
      </p>
    </Category>
    <Category title="Pizza">
      <p>
        {data.pizza.edges[0].node.frontmatter.title}
      </p>
    </Category>
  </Layout>
);

export const query = graphql`
  query {
    movies: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { category: { eq: "movies" } } },
      limit: 1
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          excerpt
          fields {
            slug
          }
        }
      }
    },
    games: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { category: { eq: "games" } } },
      limit: 1
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          excerpt
          fields {
            slug
          }
        }
      }
    },
    pizza: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { category: { eq: "pizza" } } },
      limit: 1
    ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
}
`
